/* You can add global styles to this file, and also import other style files */
@import "~swiper/swiper-bundle";

@import "main";
.ngxImageZoomContainer {
    height: 100% !important;
    width: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;

  }
  .ngxImageZoomThumbnail {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
  }