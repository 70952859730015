.appTable {
  width: 100%;
  margin-bottom: $spacer;
  color: $table-color;
  vertical-align: $table-cell-vertical-align;
  border-color: $table-border-color;
  vertical-align: middle;
  border: 1px solid $table-border-color;

  // stylelint-disable-next-line selector-max-universal
  > :not(caption) > * > * {
    @include padding(20px 20px);
    background-color: var(--#{$variable-prefix}table-bg);
    box-shadow: inset 0 0 0 9999px var(--#{$variable-prefix}table-accent-bg);
  }

  > tbody {
    vertical-align: inherit;
  }

  > thead {
    vertical-align: bottom;
  }

  // Highlight border color between thead, tbody and tfoot.
  > :not(:last-child) > :last-child > * {
    border-bottom-color: $table-group-separator-color;
  }

  th {
    font-weight: $font-weight-bold;
  }
  tr {
    &:not(:last-child) {
      border-bottom: 1px solid $table-border-color;
    }
  }
  @include media-breakpoint-down(md) {
    display: block;
    width: 100%;
    border: 0;
    tbody {
      display: block;
      width: 100%;
    }
    > :not(caption) > * > * {
      display: block;
      border: 0;
      padding: rem(5px);
      &:first-child {
        width: 100%;
      }
    }
    tr {
      display: block;
      width: 100%;
      padding: 10px 0;
      margin-left: rem(-5px);
      margin-right: rem(-5px);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }
}
